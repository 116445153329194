import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from './context/AuthContext';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import WorkbookPage from "./pages/WorkbookPage";
import RegisterPage from './pages/RegisterPage';
import PredictionPage from "./pages/PredictionPage";
//
import Home from './pages/HomePage';
import ImageViewer from './pages/ImageViewer';

// ----------------------------------------------------------------------

export default function Router() {
  const { isAuthenticated, checkAuth } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkAuth().then(() => {
      setLoading(false);
    });

  }, []);

  if (loading) {
    // Render a loading state while checking authentication
    return  <CircularProgress />
  }

  return (
    <Routes>
      <Route
        path="dashboard"
        element={isAuthenticated ? <DashboardLayout /> : <Navigate to="/login" />}
      >
        <Route index element={<Home />} />
        <Route path="user" element={<UserPage />} />
        <Route path="products" element={<ProductsPage />} />
        <Route path="blog" element={<BlogPage />} />
        <Route path="home" element={<Home />} />
        <Route path="prediction" element={<PredictionPage />} />
        <Route path="image/:fileName" element={<ImageViewer />} />
        <Route path="workbook/:id" element={<WorkbookPage />} />
      </Route>

      <Route
        path="login"
        element={isAuthenticated ? <Navigate to="/dashboard" /> : <LoginPage />}
      />

<Route
        path="register"
        element={isAuthenticated ? <Navigate to="/dashboard" /> : <RegisterPage />}
      />

      <Route
        path="/*"
        element={<SimpleLayout />}
      >
        <Route index element={<Navigate to="/dashboard" />} />
        <Route path="404" element={<Page404 />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>

      <Route
        path="*"
        element={<Navigate to="/404" replace />}
      />
    </Routes>
  );
}
