import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Stack, Typography, Button, Fab, Tooltip, Box } from '@mui/material';

import { useAuth } from '../context/AuthContext';
// ----------------------------------------------------------------------

export default function ProductsPage() {
  const { user } = useAuth();

  const [sourceImage, setSourceImage] = useState(null);
  const [showEditor, setShowEditor] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file);

      try {
        // Make the POST request to the API
        const response = await axios.post('https://viume-papsmear-ml-bjh4uafqtq-tl.a.run.app/predict', formData, {
          responseType: 'blob', // Request response as a binary blob
        });

        // Create a Blob URL for the image response
        const blobUrl = URL.createObjectURL(response.data);

        // Set the source image to the Blob URL
        setSourceImage(blobUrl);
        setShowEditor(true); // Show the image editor
        setLoading(false);
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Error uploading file. Please try again later.');
        setShowEditor(false); // Hide the image editor
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title> Prediction </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Single Image Prediction
        </Typography>

        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          {/* Input element to select a file */}
          <label htmlFor="fileInput">
            <input
              type="file"
              accept="image/*"
              onChange={handleFileInputChange}
              style={{ display: 'none' }}
              id="fileInput"
            />
            <Button variant="contained" component="span">
              Upload and Predict
            </Button>
          </label>
        </Stack>

        <Box
          sx={{
            width: '100%',
            height: '600px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {loading && <CircularProgress sx={{ width: '50px', height: '50px' }} />}
          {showEditor && sourceImage && (
            <FilerobotImageEditor
              source={sourceImage}
              onSave={(editedImageObject, designState) => console.log('saved', editedImageObject, designState)}
              annotationsCommon={{
                fill: '#ff0000',
              }}
              Text={{ text: 'VIUME' }}
              Rotate={{ angle: 90, componentType: 'slider' }}
   
              tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.RESIZE, TABS.FILTERS, TABS.FINETUNE]}
              defaultTabId={TABS.RESIZE}
              defaultToolId={TOOLS.PEN}
            />
          )}
        </Box>
      </Container>
    </>
  );
}
