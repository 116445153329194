import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const WORKSPACE_NAME = [
  "Aldin",
  "Gatot",
  "Kirito",
  "Sukuna",
  "Luffy"
];

// ----------------------------------------------------------------------

const products = WORKSPACE_NAME.map((name) => {

  return {
    id: faker.datatype.uuid(),
    cover: `/assets/images/products/folder.png`,
    name,
  };
});

export default products;
