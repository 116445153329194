import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Container, Stack, Typography, Button, Box } from '@mui/material';
import axios from 'axios';

// components
import { WorkspaceList } from '../sections/@dashboard/workspace';
import DialogWorkbook from '../components/dialog/DialogWorkbook';
// mock
import PRODUCTS from '../_mock/workspace';


// ----------------------------------------------------------------------

export default function HomePage() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [workspace, setWorkspace] = useState([]);
  const [updated, setUpdated] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleDelete = () => {
    setUpdated(!updated);
  }

  const handleCreateWorkbook = (name, collaborator, description = '') => {
    // TODO: create workbook POST "https://apiviu.ashoka-dashboard.com/workspace"
    axios
      .post(
        'https://apiviu.ashoka-dashboard.com/workspace',
        { name, collaborator, description },
        { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      )
      .then((response) => {
        setUpdated(!updated);
      });
  };

  useEffect(() => {
    try {
      axios
        .get('https://apiviu.ashoka-dashboard.com/workspace', {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        })
        .then((response) => {
          setWorkspace(response.data.workspace);
        });
    } catch (error) {
      console.error('Error fetching user data:', error.response?.data?.message || 'Unknown error');
    }
  }, [updated]);

  return (
    <>
      <Helmet>
        <title> Workspace </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Workspace
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <Button onClick={handleOpenDialog} variant="contained">
              Add Workbook
            </Button>
          </Stack>
        </Stack>

        {workspace.length > 0 ? (
          <WorkspaceList items={workspace} onDelete={handleDelete} />
        ) : (
          <Box textAlign="center" py={5}>
            <img src={'/assets/illustrations/workspace_kosong.png'} alt="No Data" style={{ maxWidth: '100%', margin: '0 auto' }} />
          </Box>
        )}
        <DialogWorkbook isOpen={isDialogOpen} onClose={handleCloseDialog} onCreate={handleCreateWorkbook} />
      </Container>
    </>
  );
}
