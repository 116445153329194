import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

// @mui

import CircularProgress from '@mui/material/CircularProgress';
import FaceIcon from '@mui/icons-material/Face';

import { Container, Stack, Typography, Button, Box, Chip } from '@mui/material';
// components
import DialogUpload from '../components/dialog/DialogUpload';
import { useAuth } from '../context/AuthContext';
import { FolderList } from '../sections/@dashboard/folder';

// mock
import WORKBOOK from '../_mock/workbook';

// ----------------------------------------------------------------------

export default function WorkbookPage() {
  const params = useParams();
  const [workspace, setWorkspace] = useState({});
  const [loading, setLoading] = useState(true);
  const [forbidden, setForbidden] = useState(false);
  const { user } = useAuth();
  const [workbook, setWorkbook] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [updated, setUpdated] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleDelete = (paramnya) => {
    setUpdated(!updated);
  }

  const handleUpload = (name, imageFile) => {
    
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData();
    formData.append('file', imageFile);
    formData.append('collaborator', workspace.collaborator);
    formData.append('workspace_id', params.id);
    formData.append('name', name);
    formData.append('isPublic', workspace.isPublic);


    axios.post('https://apiviu.ashoka-dashboard.com/image', formData, headers).then((response) => {
      setUpdated(!updated);
    });
  };

  useEffect(() => {
    setLoading(true);

    try {
      const headers = {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      };

      // Fetch workspace data using params.id
      axios
        .get(`https://apiviu.ashoka-dashboard.com/workspace/${params.id}`, headers)
        .then((response) => {
          setWorkspace(response.data.workspace);
          setForbidden(false);
          
          // Get the workbook ID from the workspace data
          const workbookId = response.data.workspace._id;

          // Fetch workbook data using the workbookId
          axios
            .get(`https://apiviu.ashoka-dashboard.com/workbook/${workbookId}`, headers)
            .then((response) => {
              setWorkbook(response.data.workbook);
              
              setLoading(false);
            })
            .catch((error) => {
              console.error('Error fetching workbook data:', error);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.error('Error fetching workspace data:', error);
          setForbidden(true);
          setLoading(false);
        });
    } catch (error) {
      console.error('Error:', error);
      setForbidden(true);
      setLoading(false);
    }
  }, [updated]);

  if (loading) {
    return (
      <Container maxWidth="xl">
        <Box textAlign="center" py={5}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title> Workspace </title>
      </Helmet>

      <Container maxWidth="xl">
        {forbidden ? (
          <Box textAlign="center" py={5}>
            <img
              src={'/assets/illustrations/workspace_forbidden.png'}
              alt="No Data"
              style={{ maxWidth: '100%', margin: '0 auto' }}
            />
          </Box>
        ) : (
          !loading && (
            <>
              <Typography variant="h4">{`${user.username} > ${workspace.name}`}</Typography>
              {workspace.description && (
                <Typography variant="body2" sx={{ mb: 1 }}>
                  {workspace.description}
                </Typography>
              )}
              <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-start">
                <Stack direction="row" spacing={1} flexShrink={0}>
                  <Chip icon={<FaceIcon />} label={workspace.email} variant="outlined" />
                </Stack>
              </Stack>

              <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end">
                <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                  <Button variant="contained" onClick={handleOpenDialog}>
                    Add File
                  </Button>
                </Stack>
              </Stack>

              {workbook.length > 0 ? (
                <FolderList items={workbook} onDelete={handleDelete} />
              ) : (
                <Box textAlign="center" py={5}>
                  <img
                    src={'/assets/illustrations/workbook_kosong.png'}
                    alt="No Data"
                    style={{ maxWidth: '100%', margin: '0 auto' }}
                  />
                </Box>
              )}
              <DialogUpload isOpen={isDialogOpen} onClose={handleCloseDialog} onUpload={handleUpload} />
            </>
          )
        )}
      </Container>
    </>
  );
}
