import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';

const OpenSeadragonViewer = ({ fileName, user}) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    console.log(user)

    const iframe = iframeRef.current;
    const info = {
      fileName,
      xmlUrl: `https://apiviu.ashoka-dashboard.com/xmltiles/${fileName}`,
      tileUrl: `https://apiviu.ashoka-dashboard.com/tiles/${fileName}/`,
      user
    };
    console.log(info);
    if (iframe) {
      const handleIframeLoad = () => {
        iframe.contentWindow.postMessage({ info }, '*');
      };

      // Attach load event listener
      iframe.addEventListener('load', handleIframeLoad);

      // Remove the event listener when component unmounts
      return () => {
        iframe.removeEventListener('load', handleIframeLoad);

      };
    }

    // Return undefined to satisfy the consistent-return rule
    return undefined;
  }, [fileName]);

  return (
    <div style={{ border: 'none' }}>
      {' '}
      {/* Remove border around the container */}
      <iframe
        title="openseadragon-viewer"
        ref={iframeRef}
        src={`/openseadragon.html?timestamp=${Date.now()}`}
        style={{ width: '100%', height: '653px', border: 'none' }}
      />
    </div>
  );
};

export default OpenSeadragonViewer;