import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import Iconify from '../../../components/iconify';
import { useAuth } from '../../../context/AuthContext';


export default function RegisterForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const { register, isAuthenticated } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [usernameError, setUsernameError] = useState('');

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (value) => {
    if (!value) {
      setEmailError('Email is required');
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const validatePassword = (value) => {
    if (!value) {
      setPasswordError('Password is required');
    } else {
      setPasswordError('');
    }
  };

  const validateFirstName = (value) => {
    if (!value) {
      setFirstNameError('First name is required');
    } else {
      setFirstNameError('');
    }
  };

  const validateLastName = (value) => {
    if (!value) {
      setLastNameError('Last name is required');
    } else {
      setLastNameError('');
    }
  };

  const validateUsername = (value) => {
    if (!value) {
      setUsernameError('Username is required');
    } else {
      setUsernameError('');
    }
  };

  const handleClick = async () => {
    validateEmail(email);
    validatePassword(password);
    validateFirstName(firstName);
    validateLastName(lastName);
    validateUsername(username);

    if (!emailError && !passwordError && !firstNameError && !lastNameError && !usernameError) {
      // register with axios to https://apiviu.ashoka-dashboard.com/register
        try {
            const response = await axios.post('https://apiviu.ashoka-dashboard.com/register', { email, password, 'first_name':firstName, 'last_name':lastName, username }); 
            navigate('/login', { replace: true });   
        } catch (error) {
            console.error('Register failed:', error.response.data.message);
        }
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          type="email"
          label="Email address"
          onChange={(e) => {
            setEmail(e.target.value);
            validateEmail(e.target.value);
          }}
          required
          error={Boolean(emailError)}
          helperText={emailError}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => {
            setPassword(e.target.value);
            validatePassword(e.target.value);
          }}
          required
          error={Boolean(passwordError)}
          helperText={passwordError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <Iconify icon="mdi:eye-off-outline" /> : <Iconify icon="mdi:eye-outline" />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          name="firstName"
          label="First name"
          onChange={(e) => {
            setFirstName(e.target.value);
            validateFirstName(e.target.value);
          }}
          required
          error={Boolean(firstNameError)}
          helperText={firstNameError}
        />

        <TextField
          name="lastName"
          label="Last name"
          onChange={(e) => {
            setLastName(e.target.value);
            validateLastName(e.target.value);
          }}
          required
          error={Boolean(lastNameError)}
          helperText={lastNameError}
        />

        <TextField
          name="username"
          label="Username"
          onChange={(e) => {
            setUsername(e.target.value);
            validateUsername(e.target.value);
          }}
          required
          error={Boolean(usernameError)}
          helperText={usernameError}
        />
      </Stack>

      <LoadingButton sx={{my: 3}} fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Register
      </LoadingButton>
    </>
  );
}
