import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import ChatIcon from '@mui/icons-material/Chat';
import { Container, Stack, Typography, Button, Fab, Tooltip } from '@mui/material';
// components
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
// mock
import PRODUCTS from '../_mock/workspace';
import OpenSeaPlain from '../components/deepzoom/OpenSeaDragonPlain';
import { useAuth } from '../context/AuthContext';
// ----------------------------------------------------------------------


export default function ProductsPage() {
  const params = useParams();
  const { user } = useAuth();
  const handleChatClick = () => {
    // 
  };
  return (
    <>
      <Helmet>
        <title> Workspace </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Image Viewer
        </Typography>

        <OpenSeaPlain fileName={params.fileName} user={user} />

      </Container>
    </>
  );
}
