import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
// @mui
import { Card, Link, Typography, Stack, Menu, MenuItem, IconButton, Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  cursor: 'pointer',
});

// ----------------------------------------------------------------------

WorkspaceCard.propTypes = {
  items: PropTypes.object,
};

export default function WorkspaceCard({ items, onDelete, ...other }) {
  const { name, cover, collaborator, description, email } = items;
  const navigate = useNavigate();

  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleEditClick = () => {
    // Handle edit action here
    handleMenuClose();
  };

  const handleOpenClick = () => {
    // Handle edit action here
    navigate(`/dashboard/workbook/${items._id}`);
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    // Handle delete action here
    try {
      axios.delete(`https://apiviu.ashoka-dashboard.com/workspace/${items._id}`, 
        { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      ).then((response) => { onDelete();});
    } catch (error) {
      
      console.error('Delete failed:', error.response.data.message);
    }
    handleMenuClose();
  };

  return (
    <Card>
      <IconButton
        aria-label="options"
        aria-controls="product-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        sx={{ position: 'absolute', top: 8, right: 8, zIndex: 10 }}
      >
        <MoreVertIcon />
      </IconButton>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <StyledProductImg onClick={handleOpenClick} alt={name} src={'/assets/images/products/folder.png'} />
      </Box>

      <Box sx={{ ml: 1, mt: -4, p: 2 }}>
        <Typography variant="h6" noWrap>
          {name}
        </Typography>
        <Typography variant="caption" noWrap>
          {description}
        </Typography>
      </Box>

      <Menu id="product-menu" anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleMenuClose}>
        <MenuItem onClick={handleOpenClick}>Open</MenuItem>
        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
      </Menu>
    </Card>
  );
}
