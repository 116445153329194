import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const WORKSPACE_NAME = [
  "pc22-0664",
  "rsy22-039",
];

// ----------------------------------------------------------------------

const products = WORKSPACE_NAME.map((name) => {

  return {
    id: faker.datatype.uuid(),
    cover: `/assets/images/patholog/papsmear.png`,
    name,
  };
});

export default products;
