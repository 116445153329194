import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography } from '@mui/material';

export default function CreateWorkbookDialog({ isOpen, onClose, onUpload }) {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [imageFile, setImageFile] = useState('');

  const validateName = (value) => {
    if (!value) {
      setNameError('Name is required');
    } else {
      setNameError('');
    }
  };

  const handleUpload = () => {
    if (!nameError && name && imageFile) {
     onUpload(name, imageFile); 
     onClose();
      
    } else {
        setNameError('Name is required');
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0]; // Get the uploaded file
    setImageFile(file); // Store the file in state

  };

  const handleClose = () => {
    setName('');
    setNameError('');
    setImageFile('');
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Add File to Workbook</DialogTitle>
      <DialogContent>
        <TextField
          name="name"
          label="Name"
          fullWidth
          onChange={(e) => {
            validateName(e.target.value);
            setName(e.target.value);
          }}
          required
          error={Boolean(nameError)}
          helperText={nameError}
          sx={{ mt: 1 }}
        />

        <label htmlFor="image-upload-input">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
            id="image-upload-input"
          />
          <Button  fullWidth variant="outlined" component="span" sx={{mt: 2}}>
            Upload Image
          </Button>
        </label>

        {imageFile && (<Typography variant="caption" sx={{ margin: '0 auto' }}>{imageFile.name}</Typography>)}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleUpload} color="primary">
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
